<div
  *ngIf="pageTransition.page === 'list'"
  [@slideInOutAnimation]="pageTransition.direction"
>
  <div class="hierarchy-list-header">
    <div
      *ngIf="type === 'filter' && canClearAll && !isSpecialtySearch"
      class="clear-all"
    >
      <a
        class="reset-filters"
        tabindex="0"
        data-cy="hierarchy-list.clear-all"
        (click)="clearFilters()"
        (keydown.enter)="clearFilters()"
      >
        {{ 'search_filter_clear_all' | translate }}
      </a>
    </div>
    <h4 class="title-container" (click)="closeOverlay()">
      <span class="title" [ngClass]="{ 'clear-filter': canClearAll }">
        {{ data.title | translate }}
      </span>
      <mat-icon
        svgIcon="dls-times-plain-light"
        class="absolute right-0 mr-3 h-[26px] w-[26px]"
        data-cy="hierarchy-list.close-button"
      >
      </mat-icon>
    </h4>
  </div>

  <ul class="hierarchy-list-dropdown" *ngIf="type === 'filter'">
    <li
      *ngFor="let filter of filters; trackBy: trackByFilter"
      [attr.data-cy]="'hierarchy-list.mobile-filter-' + filter.name"
      class="mobile-filter-{{ filter.name }} flex items-center justify-between"
      [ngClass]="{ selected: filter.selected }"
      (click)="
        onGoToOverlayPage(
          { page: 'nested-options', direction: 'forward' },
          filter.name
        )
      "
    >
      {{ filter.name | translate }}
      <mat-icon
        svgIcon="dls-caret-right-solid"
        *ngIf="filter.options"
        class="mr-3"
      >
      </mat-icon>
    </li>
  </ul>

  <ng-container *ngIf="type === 'refine'">
    <ng-container *ngIf="!isSpecialtySearch; else specialtySearchRefine">
      <div *ngIf="checkboxFilters" class="pad bottom-2x top-3x">
        <span
          class="display-block fs-16px lh-24px strong pad left-2x bottom-2x"
        >
          {{ 'search_filter_show_only' | translate }}
        </span>
        <div
          class="mobile-checkbox-container pad left-2x"
          *ngFor="let checkbox of checkboxFilters; trackBy: trackByFilter"
        >
          <app-search-checkbox
            [filter]="checkbox"
            (filterSelected)="onCheckboxSelection($event)"
          >
          </app-search-checkbox>
        </div>
      </div>

      <div
        *ngIf="radiusFilters && !incented && searchFilters.radiusOptionIsValid"
        class="pad bottom-3x"
      >
        <div class="fs-16px lh-24px strong pad left-2x bottom-1x">
          {{ 'search_filter_refine_distance' | translate }}
        </div>
        <div
          class="mobile-radius"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="
            onGoToOverlayPage(
              { page: 'nested-options', direction: 'forward' },
              'radius'
            )
          "
        >
          <span class="pad left-2x top-2x bottom-2x">
            {{ radiusFilters.selected }} {{ 'app_global_miles' | translate }}
          </span>
          <mat-icon svgIcon="dls-caret-right-solid" class="mr-3"></mat-icon>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #specialtySearchRefine>
    <ul class="hierarchy-list-dropdown">
      <li class="strong">{{ 'search_sort_by' | translate }}</li>
      <li
        *ngFor="
          let option of sortConfig[searchParamType];
          trackBy: trackByFilter
        "
        class="
          mobile-filter-{{ option.translation }} pad right-3x
          flex items-center justify-between
        "
        [ngClass]="{ selected: option.query === sortConfig?.selected?.query }"
        (click)="onSortSelected(option)"
      >
        {{ option.translation | translate }}
        <mat-icon
          svgIcon="dls-check-plain-light"
          class="selected-checkmark h-[30px] w-[30px]"
          *ngIf="option.query === sortConfig?.selected?.query"
          [attr.aria-label]="'app_global_selected' | translate"
        >
        </mat-icon>
      </li>
    </ul>
  </ng-template>

  <ng-container *ngIf="type === 'network'">
    <app-global-network-dropdown
      analytics
      [disableDropdown]="false"
      [hideFormField]="true"
      appearance="fill"
    >
    </app-global-network-dropdown>
  </ng-container>
</div>

<app-hierarchy-list-nested-options
  *ngIf="pageTransition.page === 'nested-options'"
  [@slideInOutAnimation]="pageTransition.direction"
  (goToOverlayPage)="onGoToOverlayPage($event, selectedFilter?.name)"
  [(filter)]="selectedFilter"
  [isSpecialtySearch]="isSpecialtySearch"
  [type]="type"
  [searchParamType]="searchParamType"
>
</app-hierarchy-list-nested-options>

import { SearchFiltersFacet } from '@interfaces/search-filters-facet.model';
import { AwardTypeFilter } from '@components/+search/classes/award-type-filter';

export class SearchFiltersFacets {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aggregate_overall_rating[$gte]': SearchFiltersFacet[];
  'average_wait_time': SearchFiltersFacet[];
  'award_type_codes': SearchFiltersFacet[];
  'bdc_type_codes': SearchFiltersFacet[];
  'blue_compare': SearchFiltersFacet[];
  'contract_accepting_new_patients': SearchFiltersFacet[];
  'cqms': SearchFiltersFacet[];
  'ecp_category_codes': SearchFiltersFacet[];
  'expertise_codes': SearchFiltersFacet[];
  'field_specialty_ids': SearchFiltersFacet[];
  'group_affiliation_ids': SearchFiltersFacet[];
  'freestanding_facilities_type_codes': SearchFiltersFacet[];
  'island_type_codes': SearchFiltersFacet[];
  'has_bdc_affiliated_facility': SearchFiltersFacet[];
  'has_incentive': SearchFiltersFacet[];
  'hospital_affiliation_ids': SearchFiltersFacet[];
  'is_certified': SearchFiltersFacet[];
  'is_pcp': SearchFiltersFacet[];
  'local_pcp': SearchFiltersFacet[];
  'regional_pcp': SearchFiltersFacet[];
  'national_pcp': SearchFiltersFacet[];
  'is_choice_pcp': SearchFiltersFacet[];
  'leapfrog_grade_values': SearchFiltersFacet[];
  'location_city_state': SearchFiltersFacet[];
  'location_geo': SearchFiltersFacet[];
  'location_languages': SearchFiltersFacet[];
  'location_zip': SearchFiltersFacet[];
  'professional_gender': SearchFiltersFacet[];
  'professional_languages': SearchFiltersFacet[];
  'provider_type': SearchFiltersFacet[];
  'grouphealth_providers_type_codes': SearchFiltersFacet[];
  'enhanced_benefits_values': SearchFiltersFacet[];
  'mammography_providers_values': SearchFiltersFacet[];
  'mammogram_providers_values': SearchFiltersFacet[];
  'embold_providers_values': SearchFiltersFacet[];
  'retail_health_values': SearchFiltersFacet[];
  'mobile_provider_values': SearchFiltersFacet[];
  'ahn_providers_values': SearchFiltersFacet[];
  'coe_type_codes': SearchFiltersFacet[];
  'referral_network_ids': SearchFiltersFacet[];
  'pcmh_providers_values': SearchFiltersFacet[];
  'provider_type_name': SearchFiltersFacet[];
  'provider_type_description': SearchFiltersFacet[];
  'telehealth_values': SearchFiltersFacet[];
  'extended_hours_type_codes': SearchFiltersFacet[];
  'handicap_accessible_values': SearchFiltersFacet[];
  'ages_treated_type_codes': SearchFiltersFacet[];
  'disorders_treated_type_codes': SearchFiltersFacet[];
  'bdtc_values': SearchFiltersFacet[];
  'treatment_methods_type_codes': SearchFiltersFacet[];
  'ada_building_amenities_type_codes': SearchFiltersFacet[];
  'age_limitations': SearchFiltersFacet[];
  'vbidri_providers_values': SearchFiltersFacet[];
  'enhanced_provider_values': SearchFiltersFacet[];
  'cultural_competency_values': SearchFiltersFacet[];
  'provider_services_type_codes': SearchFiltersFacet[];
  'adv_professionals_values': SearchFiltersFacet[];
  'nmh_facilities_values': SearchFiltersFacet[];
  'quality_score_type_codes': SearchFiltersFacet[];
  'verified_data': SearchFiltersFacet[];
  'provider_verified_values': SearchFiltersFacet[];
  'essential_provider_values': SearchFiltersFacet[];
  'race_type_codes': SearchFiltersFacet[];
  'ethnicity_type_codes': SearchFiltersFacet[];
  'quality_blue_type_codes': SearchFiltersFacet[];
  'parish_type_codes': SearchFiltersFacet[];
  'concierge_values': SearchFiltersFacet[];
  'preferred_pharmacy_values': SearchFiltersFacet[];
  'accept_medicaid_values': SearchFiltersFacet[];
  'virtual_only': SearchFiltersFacet[];
  'offers_virtual_care': SearchFiltersFacet[];
  'direct_delegated_type_codes': SearchFiltersFacet[];
  'medication_assisted_treatment_values': SearchFiltersFacet[];
  'hiv_experienced_values': SearchFiltersFacet[];

  constructor(obj?: SearchFiltersFacets, tier_code?: string) {
    this['aggregate_overall_rating[$gte]'] =
      obj['aggregate_overall_rating'] || null;
    this['average_wait_time'] = obj['average_wait_time'] || null;
    this['award_type_codes'] = obj['award_type_codes']
      ? new AwardTypeFilter(obj).facets()
      : null;
    this['bdc_type_codes'] = obj['bdc_type_codes'] || null;
    this['blue_compare'] = obj['blue_compare'] || null;
    this['contract_accepting_new_patients'] =
      obj['contract_accepting_new_patients'] || null;
    this['cqms'] = obj['cqms'] || null;
    this['ecp_category_codes'] = obj['ecp_category_codes'] || null;
    this['expertise_codes'] = obj['expertise_codes'] || null;
    this['field_specialty_ids'] = obj['field_specialty_ids'] || null;
    this['group_affiliation_ids'] = obj['group_affiliation_ids'] || null;
    this['has_bdc_affiliated_facility'] =
      obj['has_bdc_affiliated_facility'] || null;
    this['has_incentive'] = obj['has_incentive'] || null;
    this['hospital_affiliation_ids'] = obj['hospital_affiliation_ids'] || null;
    this['is_certified'] = obj['is_certified'] || null;
    this['is_pcp'] = obj['is_pcp'] || null;
    this['local_pcp'] = obj['local_pcp'] || null;
    this['regional_pcp'] = obj['regional_pcp'] || null;
    this['national_pcp'] = obj['national_pcp'] || null;
    this['is_choice_pcp'] = obj['is_choice_pcp'] || null;
    this['leapfrog_grade_values'] = obj['leapfrog_grade_values'] || null;
    this['location_city_state'] = obj['location_city_state'] || null;
    this['location_geo'] = obj['location_geo'] || null;
    this['location_languages'] = obj['location_languages'] || null;
    this['location_zip'] = obj['location_zip'] || null;
    this['professional_gender'] = obj['professional_gender'] || null;
    this['professional_languages'] = obj['professional_languages'] || null;
    this['provider_type'] = obj['provider_type'] || null;
    this['grouphealth_providers_type_codes'] =
      obj['grouphealth_providers_type_codes'] || null;
    this['enhanced_benefits_values'] = obj['enhanced_benefits_values'] || null;
    this['mammography_providers_values'] =
      obj['mammography_providers_values'] || null;
    this['mammogram_providers_values'] =
      obj['mammogram_providers_values'] || null;
    this['embold_providers_values'] = obj['embold_providers_values'] || null;
    this['retail_health_values'] = obj['retail_health_values'] || null;
    this['mobile_provider_values'] = obj['mobile_provider_values'] || null;
    this['coe_type_codes'] = obj['coe_type_codes'] || null;
    this['referral_network_ids'] = obj['referral_network_ids'] || null;
    this['ahn_providers_values'] = obj['ahn_providers_values'] || null;
    this['pcmh_providers_values'] = obj['pcmh_providers_values'] || null;
    this['provider_type_name'] = obj['provider_type_name'] || null;
    this['provider_type_description'] =
      obj['provider_type_description'] || null;
    this['provider_type_description'] =
      obj['provider_type_description'] || null;
    this['telehealth_values'] = obj['telehealth_values'] || null;
    this['extended_hours_type_codes'] =
      obj['extended_hours_type_codes'] || null;
    this['handicap_accessible_values'] =
      obj['handicap_accessible_values'] || null;
    this['ages_treated_type_codes'] = obj['ages_treated_type_codes'] || null;
    this['disorders_treated_type_codes'] =
      obj['disorders_treated_type_codes'] || null;
    this['treatment_methods_type_codes'] =
      obj['treatment_methods_type_codes'] || null;
    this['quality_score_type_codes'] = obj['quality_score_type_codes'] || null;
    if (tier_code) {
      this['tiers:' + tier_code.toLowerCase()] =
        obj['tiers:' + tier_code.toLowerCase()] || null;
    }
    this['ada_building_amenities_type_codes'] =
      obj['ada_building_amenities_type_codes'] || null;
    this['age_limitations'] = obj['age_limitations'] || null;
    this['vbidri_providers_values'] = obj['vbidri_providers_values'] || null;
    this['enhanced_provider_values'] = obj['enhanced_provider_values'] || null;
    this['cultural_competency_values'] =
      obj['cultural_competency_values'] || null;
    this['provider_services_type_codes'] =
      obj['provider_services_type_codes'] || null;
    this['adv_professionals_values'] = obj['adv_professionals_values'] || null;
    this['nmh_facilities_values'] = obj['nmh_facilities_values'] || null;
    this['verified_data'] = obj['verified_data'] || null;
    this['provider_verified_values'] = obj['provider_verified_values'] || null;
    this['freestanding_facilities_type_codes'] =
      obj['freestanding_facilities_type_codes'] || null;
    this['island_type_codes'] = obj['island_type_codes'] || null;
    this['essential_provider_values'] =
      obj['essential_provider_values'] || null;
    this['race_type_codes'] = obj['race_type_codes'] || null;
    this['ethnicity_type_codes'] = obj['ethnicity_type_codes'] || null;
    this['bdtc_values'] = obj['bdtc_values'] || null;
    this['quality_blue_type_codes'] = obj['quality_blue_type_codes'] || null;
    this['parish_type_codes'] = obj['parish_type_codes'] || null;
    this['concierge_values'] = obj['concierge_values'] || null;
    this['preferred_pharmacy_values'] =
      obj['preferred_pharmacy_values'] || null;
    this['accept_medicaid_values'] = obj['accept_medicaid_values'] || null;
    this['virtual_only'] = obj['virtual_only'] || null;
    this['offers_virtual_care'] = obj['offers_virtual_care'] || null;
    this['direct_delegated_type_codes'] = obj['direct_delegated_type_codes'] || null;
    this['medication_assisted_treatment_values'] = obj['medication_assisted_treatment_values'] || null;
    this['hiv_experienced_values'] = obj['hiv_experienced_values'] || null;
  }
}

import {
  Component,
  forwardRef,
  Input,
  Output,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Network } from '@interfaces/network.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { iif, Observable, of, Subscription, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { NetworkStoreSelectors } from '@store/network';
import { AuthService } from '@services/auth.service';
import {
  first,
  map,
  switchMap,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';
import { SettingsService } from '@services/settings.service';
import { WindowService } from '@services/window.service';
import { FixedTooltipComponent } from '../../fixed-tooltip/fixed-tooltip.component';
import { AlphaPrefixConfig } from '@interfaces/alpha-prefix-config.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NetworkAlphaPrefixDialogComponent } from '../../network-alpha-prefix-dialog/network-alpha-prefix-dialog.component';
import { NetworkAlphaPrefixDialogCloseData } from '@interfaces/network-alpha-prefix-dialog-close-data.interface';
import { Breakpoints } from '@classes/breakpoints.class';
import { NetworksService } from '@services/networks.service';
import { AppParamsService } from '@services/app.params.service';
import { CcssService } from '@services/ccss/ccss.service';
import { HierarchyListComponent } from '../../hierarchy-list/hierarchy-list.component';
import { GatedEntryService } from '@services/gated-entry/gated-entry.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { NetworkToutService } from '@services/network-tout/network-tout.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-global-network-dropdown',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GlobalNetworkDropdownComponent),
      multi: true,
    },
  ],
  styleUrls: ['./global-network-dropdown.component.scss'],
  templateUrl: './global-network-dropdown.component.html',
})
export class GlobalNetworkDropdownComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChildren(FixedTooltipComponent)
  networkToutTooltips: QueryList<FixedTooltipComponent>;
  @ViewChild('selectRef') selectRef: MatSelect;

  @Input() hideFormField: boolean = false;
  @Input() disableDropdown: boolean = false;
  @Input() darkTheme: boolean = false;
  @Input() showTout: boolean = false;
  @Input() showAlphaPrefixTout: boolean = false;
  @Input() public autoSelect: boolean = true;
  @Input() public appearance: string = 'outline';
  @Input() mobile: boolean = false;

  @Output() selectedValue: EventEmitter<string> = new EventEmitter();
  @Output() selectedNetwork: EventEmitter<Network> = new EventEmitter();

  public mappedNetworks: Network[];
  public selectedOption: string = '*';
  public placeholder: string;
  public state: string;
  public toutEnabled: boolean = false;
  public tout: boolean = false;
  public toutDismissed: boolean = false;
  public alphaPrefixConfig: AlphaPrefixConfig;
  public alphaPrefixNetworkToutEnabled: boolean = false;
  public auth: AuthStatus;

  private subscriptions = new SubscriptionManager();
  private networkChanged: boolean = false;

  constructor(
    public breakpoints: Breakpoints,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private networksService: NetworksService,
    private appParamsService: AppParamsService,
    private ccssService: CcssService,
    private bottomSheet: MatBottomSheet,
    private gatedEntryService: GatedEntryService,
    private networkToutService: NetworkToutService,
    private authService: AuthService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToMappedNetworks());
    this.getResolvedNetworkId();
    this.subscriptions.add(this.subscribeToAuthStore());
    this.initAlphaPrefixTout();
    this.applyNetworkTrayStyling();
  }

  ngAfterViewInit() {
    this.openMobileSelection();
    this.refreshNetworkDropdown();
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
    this.removeNetworkTrayStyling();
  }

  public onTooltipClose(): void {
    this.windowService['sessionStorage'].setItem('toutNetworkSelection', false);
    this.tout = false;
    this.toutDismissed = true;
  }

  public closeFixedTooltips(): void {
    if (this.networkToutTooltips) {
      this.networkToutTooltips.forEach((tooltip: FixedTooltipComponent) =>
        tooltip.closeTooltip()
      );
    }
  }

  public openNetworkTray(): void {
    const toutClosed =
      this.toutDismissed || !this.alphaPrefixNetworkToutEnabled;
    if (!this.tout && toutClosed && this.disableDropdown) {
      this.bottomSheet.open(HierarchyListComponent, {
        panelClass: 'full-screen-sheet',
        data: {
          type: 'network',
          title: 'app_global_network',
        },
      });
    }
  }

  public onAlphaPrefixNetworkToutClick(): void {
    this.closeFixedTooltips();
    this.initAlphaPrefixDialog();
  }

  public onAlphaPrefixNetworkToutDismiss(): void {
    this.setAlphaPrefixComplete();
  }

  public handleSelect(val: any): void {
    if (val && val !== '*') {
      this.networkChanged = true;
      this.selectedNetwork.emit(
        this.networksService.getNetworkById(this.mappedNetworks, val)
      );
      this.networksService.setNetwork(String(val));
      if (this.auth && !this.auth.auth_status) {
        this.networksService.networkSelected(val);
      }
    }
    const selectedVal = val === '*' ? null : String(val);
    this.selectedValue.emit(selectedVal);
  }

  public getPanelClass(
    hideFormField: boolean,
    disableDropdown: boolean
  ): string {
    const classes = [];

    if (hideFormField) {
      classes.push('mobile-network-overlay');
    } else {
      classes.push('desktop-network-dropdown-pane');
    }

    if (disableDropdown) {
      classes.push('disable-network-dropdown');
    }

    return classes.join(' ');
  }

  public trackByNetworkId(index: number, network: any): number {
    return network.id;
  }

  public dismissMobileTray(): void {
    if (this.hideFormField) {
      this.selectRef.close();
      this.bottomSheet.dismiss();
    }
  }

  private subscribeToAuthStore(): Subscription {
    return this.store
      .pipe(select(AuthStoreSelectors.getAuthStatus))
      .subscribe((data) => (this.auth = data));
  }

  private startTout(): void {
    const displayDuration = 4000;
    setTimeout(() => {
      this.tout = false;
    }, displayDuration);
  }

  private initTout(): void {
    if (this.showTout) {
      this.subscriptions.add(
        this.networkToutService
          .getToutEnabled(this.isDefaultNetwork())
          .subscribe((enabled: boolean) => {
            this.toutEnabled = enabled && !this.networkChanged;
            this.tout = this.toutEnabled;
            if (this.tout) {
              this.startTout();
            }
          })
      );
    }
  }

  private isDefaultNetwork(): boolean {
    return !!(
      this.mappedNetworks &&
      this.mappedNetworks[0] &&
      (this.networksService.getSelectedNetwork() &&
        this.networksService.getSelectedNetwork().id) ===
        this.mappedNetworks[0].id
    );
  }

  private initAlphaPrefixTout(): void {
    this.alphaPrefixConfig = null;
    this.alphaPrefixNetworkToutEnabled = false;
    if (!this.showAlphaPrefixTout || this.getAlphaPrefixSessionComplete()) {
      return;
    }

    const alphaPrefixEnabledSub = combineLatest([
      this.getAlphaPrefixConfig(),
      this.gatedEntryService.status,
    ]).subscribe(([config, gateStatus]) => {
      if (!gateStatus.isEnabled || gateStatus.isClosed) {
        this.alphaPrefixConfig = config;
        this.alphaPrefixNetworkToutEnabled = config.network_enabled;
      }
    });
    this.subscriptions.add(alphaPrefixEnabledSub);
  }

  private initAlphaPrefixDialog(): void {
    this.alphaPrefixNetworkToutEnabled = false;
    const dialogRef = this.dialog.open(NetworkAlphaPrefixDialogComponent, {
      maxWidth: '872px',
      data: {
        alphaPrefixLength: this.alphaPrefixConfig.length,
        loginUrl: this.auth.url,
        requestCi: this.alphaPrefixConfig.request_ci,
        authStatus: this.auth.auth_status,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((data: NetworkAlphaPrefixDialogCloseData) =>
        this.handleAlphaPrefixDialogClose(data)
      );
  }

  private getAlphaPrefixSessionComplete(): boolean {
    let toutComplete;
    try {
      toutComplete = JSON.parse(
        this.windowService['sessionStorage'].getItem('toutAlphaPrefixComplete')
      );
    } catch (e) {
      return;
    }
    return !!toutComplete;
  }

  private handleAlphaPrefixDialogClose(
    data: NetworkAlphaPrefixDialogCloseData
  ): void {
    if (data && data.network_id) {
      this.setAlphaPrefixComplete();
      this.networksService.setNetwork(data.network_id);
    }
  }

  private setAlphaPrefixComplete(): void {
    this.alphaPrefixNetworkToutEnabled = false;
    this.windowService['sessionStorage'].setItem(
      'toutAlphaPrefixComplete',
      'true'
    );
  }

  private getAlphaPrefixConfig(): Observable<AlphaPrefixConfig> {
    return this.authService.authStatus.pipe(
      switchMap((status) =>
        iif(
          // If logged in
          () => status.auth_status === true,
          // Then not enabled
          of({ network_enabled: false }),
          // Else get config
          this.requestAlphaPrefixConfig()
        )
      ),
      map((config) => new AlphaPrefixConfig(config)),
      first()
    );
  }

  private requestAlphaPrefixConfig(): Observable<AlphaPrefixConfig> {
    return this.settingsService.getSetting('alpha_prefix', AlphaPrefixConfig);
  }

  private setMappedNetworks(mappedNetworks: Network[]): Network[] {
    const ci = this.appParamsService.params && this.appParamsService.params.ci;
    if (ci === 'INCORRECT-GROUP-NUMBER-OR-NETWORK') {
      return [
        new Network({ name: 'app_global_search_header_health_plan', id: '0' }),
      ];
    }
    return mappedNetworks;
  }

  private subscribeToMappedNetworks(): Subscription {
    return this.networksService.mappedNetworks
      .pipe(
        distinctUntilChanged((prev, curr) =>
          isEqual(
            prev.map((n) => n.id),
            curr.map((n) => n.id)
          )
        )
      )
      .subscribe((mappedNetworks) => {
        this.mappedNetworks = this.setMappedNetworks(mappedNetworks);
        this.initTout();
      });
  }

  private applyNetworkTrayStyling(): void {
    if (this.hideFormField) {
      this.ccssService.applyClassToBody('mobile-network-cdk');
    }
  }

  private openMobileSelection(): void {
    if (this.hideFormField) {
      this.subscriptions.add(
        this.selectRef.optionSelectionChanges
          .pipe(take(1))
          .subscribe(() => this.selectRef.open())
      );
    }
  }

  private removeNetworkTrayStyling(): void {
    this.ccssService.removeBodyClass('mobile-network-cdk');
  }

  private getResolvedNetworkId(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(NetworkStoreSelectors.getResolvedNetwork))
        .subscribe((network: Network) => {
          if (this.autoSelect) {
            this.selectedOption = network.id;
          }
        })
    );
  }

  private refreshNetworkDropdown(): void {
    if (this.mappedNetworks?.length >= 1) {
      setTimeout(() => {
        this.selectRef.open();
        this.selectRef.close();
      }, 1000);
    }
  }
}
